var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "自提点名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "800px" },
                attrs: { placeholder: "请输入自提点名称", clearable: "" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生效时间", prop: "timeEffect" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  placeholder: "选择时间范围",
                  clearable: "",
                },
                model: {
                  value: _vm.form.timeEffect,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "timeEffect", $$v)
                  },
                  expression: "form.timeEffect",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "地址选择", prop: "address" } },
            [
              _c("el-cascader", {
                staticStyle: { width: "320px" },
                attrs: {
                  props: _vm.siteOptions,
                  placeholder: "请选择",
                  options: _vm.siteArr,
                  clearable: "",
                },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { "max-width": "800px" },
                attrs: {
                  type: "textarea",
                  placeholder: "请输入具体地址，不超过120个字",
                  maxlength: "120",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.form.addressDetail,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "addressDetail",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.addressDetail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话", prop: "phone" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "800px" },
                attrs: {
                  placeholder: "请输入准确的联系电话，便于买家联系",
                  clearable: "",
                },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "phone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自提时间", prop: "timeType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.timeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "timeType", $$v)
                    },
                    expression: "form.timeType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v(" 全天 ")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v(" 自定义 ")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.timeType === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "每天自提时间" } },
                [
                  _c("el-time-picker", {
                    attrs: {
                      "is-range": "",
                      format: "HH:mm",
                      "value-format": "HH:mm",
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      placeholder: "选择时间范围",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.timeDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "timeDetail", $$v)
                      },
                      expression: "form.timeDetail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "40px" },
          attrs: { type: "primary", loading: _vm.loading },
          on: { click: _vm.toAdd },
        },
        [_vm._v(" 确认开启 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }