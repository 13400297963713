<template>
  <div class="outer">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item
        label="自提点名称"
        prop="name"
      >
        <el-input
          v-model.trim="form.name"
          placeholder="请输入自提点名称"
          clearable
          style="max-width: 800px"
        />
      </el-form-item>
      <el-form-item
        label="生效时间"
        prop="timeEffect"
      >
        <el-date-picker
          v-model="form.timeEffect"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          clearable
        />
      </el-form-item>
      <el-form-item
        label="地址选择"
        prop="address"
      >
        <el-cascader
          v-model="form.address"
          :props="siteOptions"
          placeholder="请选择"
          :options="siteArr"
          clearable
          style="width: 320px;"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model.trim="form.addressDetail"
          type="textarea"
          placeholder="请输入具体地址，不超过120个字"
          maxlength="120"
          show-word-limit
          style="max-width: 800px"
        />
      </el-form-item>
      <el-form-item
        label="联系电话"
        prop="phone"
      >
        <el-input
          v-model.trim="form.phone"
          placeholder="请输入准确的联系电话，便于买家联系"
          clearable
          style="max-width: 800px"
        />
      </el-form-item>
      <el-form-item
        label="自提时间"
        prop="timeType"
      >
        <el-radio-group v-model="form.timeType">
          <el-radio :label="1">
            全天
          </el-radio>
          <el-radio :label="2">
            自定义
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.timeType === 2"
        label="每天自提时间"
      >
        <el-time-picker
          v-model="form.timeDetail"
          is-range
          format="HH:mm"
          value-format="HH:mm"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          clearable
        />
      </el-form-item>
    </el-form>
    <el-button
      type="primary"
      style="margin-top: 40px"
      :loading="loading"
      @click="toAdd"
    >
      确认开启
    </el-button>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  SITE_OPTION,
  FORM_DATA,
  FORM_RULES
} from './data'

export default {
  name: 'SelfRaiseDetail',
  data() {
    return {
      siteOptions: SITE_OPTION,
      siteArr: [],
      form: { ...FORM_DATA },
      rules: FORM_RULES,
      loading: false
    }
  },
  mounted() {
    this.getAddress()
    if (this.$route.query.id) {
      this.getDetail()
    }
  },
  methods: {
    // 获取省市区街道
    getAddress() {
      this.$axios({
        method: 'get',
        url: this.$api.Address.get_four_address
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.siteArr = r.data
        }
      })
    },
    toAdd() {
      const that = this
      const isEdit = that.$route.query.id
      let url = ''
      const data = {
        name: that.form.name,
        mobile: that.form.phone,
        province: that.form.address[0],
        city: that.form.address[1],
        district: that.form.address[2],
        address: that.form.addressDetail,
        type: that.form.timeType,
        effect_start_time: new Date(that.form.timeEffect[0]).getTime() / 1000,
        effect_end_time: new Date(that.form.timeEffect[1]).getTime() / 1000
      }
      if (that.form.address[3]) {
        data.street = that.form.address[3]
      }
      if (that.form.timeType === 2 && that.form.timeDetail.length === 0) {
        that.$message.error('请选择每日自提时间')
        return false
      }
      // 选择自定时间时需要传详细时间
      if (that.form.timeType === 2) {
        data.begin_hour = that.form.timeDetail[0]
        data.end_hour = that.form.timeDetail[1]
      }
      // 判断为编辑时多传id
      if (isEdit) {
        data.id = isEdit
        url = that.$api.selfRaise.edit
      } else {
        delete data.id
        url = that.$api.selfRaise.add
      }
      that.$refs['form'].validate(valid => {
        if (valid) {
          that.loading = true
          that.$axios.post(url, data).then(res => {
            that.loading = false
            if (res.error === 0) {
              that.$message.success(isEdit ? '编辑成功' : '新增成功')
              that.$router.go(-1)
            }
          })
        }
      })
    },
    // 获取详情数据
    getDetail() {
      const that = this
      const url = that.$api.selfRaise.get_detail
      that.$axios.post(url, {
        id: that.$route.query.id
      }).then(res => {
        if (res.error === 0) {
          const province = _.get(res, 'data.info.province', 0)
          const city = _.get(res, 'data.info.city', 0)
          const district = _.get(res, 'data.info.district', 0)
          const street = _.get(res, 'data.info.street', 0)
          const time = _.get(res, 'data.info.range_time')
          that.form.name = _.get(res, 'data.info.name', '')
          const areaArr = [province, city, district]
          if (street) {
            areaArr.push(street)
          }
          that.form.address = areaArr
          that.form.addressDetail = _.get(res, 'data.info.address', '')
          that.form.phone = _.get(res, 'data.info.mobile', '')
          that.form.timeType = _.get(res, 'data.info.type', 0)
          let arr = [res.data.info.effect_start_time, res.data.info.effect_end_time]
          that.form.timeEffect = arr
          // console.log(that.form.timeEffect,'时间')
          if (that.form.timeType === 2) {
            const timeArr = time.split('-')
            that.form.timeDetail = [timeArr[0], timeArr[1]]
          }
        }
      })
    }
  }
}
</script>
